import React, { useEffect, FC, memo, useCallback } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useRoutesConfig } from "routes/routesConfig";
import DashboardLayout from "layouts/Dashboard";
import AuthLayout from "layouts/Auth";
import Page404 from "routes/Page404";
import Page403 from "routes/Page403";
import { RouteType } from "types/routes";
import store from "store/store";
import SignIn from "auth/SignIn";
import { ErrorBoundary } from "react-error-boundary";
import Page500 from "routes/Page500";
import { useAuthContext } from "auth/use-auth-context";
import { injectStore } from "store/api/utils/apiUtilv2";
import { useGetSitesQuery } from "store/services/sites";

injectStore(store);

const childRoutes = (Layout: React.ElementType, routes: Array<RouteType>) =>
  routes.map(
    ({ component: Component, guard, children, path }, index: number) => {
      if (children) {
        return children.map((child, index: number) => {
          if (child.children) {
            return [
              ...child.children.map((secondChild, secondChildIndex: number) => {
                return (
                  <Route
                    key={secondChildIndex}
                    path={secondChild.path}
                    element={
                      <AuthGuard>
                        <Layout>
                          <secondChild.component {...secondChild.props} />
                        </Layout>
                      </AuthGuard>
                    }
                  />
                );
              }),
              <Route
                key={index}
                path={child.path}
                element={
                  <AuthGuard>
                    <Layout>
                      <child.component {...child.props} />
                    </Layout>
                  </AuthGuard>
                }
              />,
            ];
          } else {
            return (
              <Route
                key={index}
                path={child.path}
                element={
                  <AuthGuard>
                    <Layout>
                      <child.component {...child.props} />
                    </Layout>
                  </AuthGuard>
                }
              />
            );
          }
        });
      } else if (Component) {
        return (
          <Route
            key={index}
            path={path}
            element={
              <AuthGuard>
                <Layout>
                  <Component />
                </Layout>
              </AuthGuard>
            }
          />
        );
      } else return null;
    }
  );

const Atlas: FC = memo(() => {
  const routesConfig = useRoutesConfig();
  const {data: sites = []} = useGetSitesQuery();

  return (
    <ErrorBoundary FallbackComponent={Page500}>
      <Routes>
        {childRoutes(DashboardLayout, routesConfig.dashboardLayoutRoutes)}
        {childRoutes(AuthLayout, routesConfig.authLayoutRoutes)}

        {/* if user enters root route they should be redirected to ShopMap, else render the 404 page */}
        <Route
          path="/"
          element={<Navigate replace to={`site/${sites[0]?.name}`} />}
        />
        <Route
          path="*"
          element={
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          }
        />
        <Route path="500" element={<Page500 />} />
        <Route
          path="login"
          element={
            <AuthLayout>
              <SignIn />
            </AuthLayout>
          }
        />
        <Route
          path="404"
          element={
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          }
        />
        <Route
          path="403"
          element={
            <AuthLayout>
              <Page403 />
            </AuthLayout>
          }
        />
      </Routes>
    </ErrorBoundary>
  );
});

const AtlasRoutes: FC = () => {
  const { authenticated } = useAuthContext();

  useEffect(function forceProductionBrowserAddress() {
    process.env.REACT_APP_FORCE_NON_HEROKU_URL &&
      process.env.REACT_APP_ATLAS_ENV === "production" &&
      (window.location.href = process.env.REACT_APP_FORCE_NON_HEROKU_URL);
  }, []);

  const main = useCallback(() => {
    switch (true) {
      case authenticated:
        return <Atlas />;
      default:
        return <SignIn />;
    }
  }, [authenticated]);

  return <>{main()}</>;
};

export default AtlasRoutes;

function AuthGuard({ children }: { children: React.ReactNode }) {
  const { authenticated, user } = useAuthContext();

  const navigate = useNavigate();
  useEffect(() => {
    if (!authenticated || !user) {
      navigate("/login", { replace: true });
    }
  }, [authenticated, user]);
  return <>{children}</>;
}

