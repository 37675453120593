import { useAuthContext } from "auth/use-auth-context";
import { fiveMinutes } from "constants/intervals";
import { useDispatch } from "react-redux";
import { useGetConfigsQuery } from "store/services/config";
import { setGlobalAlert } from "store/slices/systemSlice";
import store from "store/store";

export enum FeatureType {
  ZIPPY_DASHBOARD = "ZIPPY_DASHBOARD",
  PROJECT_DASHBOARD = "PROJECT_DASHBOARD",
  LABOUR_DASHBOARD = "LABOUR_DASHBOARD",
  ISSUES_DASHBOARD = "ISSUES_DASHBOARD",
  SITE = "SITE",
  MAP = "MAP",
  STATION = "STATION",
  EPREQ = "EPREQ",
  ROLLCALL = "ROLLCALL",
  ZIPPY = "ZIPPY",
  MESSAGE = "MESSAGE",
  PROJECT = "PROJECT",
  PLAN = "PLAN",
  TAKTSHEET = "TAKTSHEET",
  LINE_MOVE = "LINE_MOVE",
  MATERIAL = "MATERIAL",
  EMPLOYEE = "EMPLOYEE",
  CHOO_CHOO = "CHOO_CHOO",
  QUALIFICATION = "QUALIFICATION",
  USER = "USER",
  PERMISSION = "PERMISSION",
  CONTINUOUS_IMPROVEMENT = "CONTINUOUS_IMPROVEMENT",
  BKM = "BKM",
  SKILLS = "SKILLS",
  SUPER_USER = "SUPER USER",
  ADMINISTRATOR = "ADMINISTRATOR",
  ISSUE = "ISSUE"
}

export enum ActionType {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  ARCHIVE = "ARCHIVE",
  LIMITED_UPDATE = "LIMITED_UPDATE",
  SYNC = "SYNC",
}

export enum UIActionType {
  DISPLAY = "DISPLAY",
  ONCLICK = "ONCLICK",
}

export const useUserRoles = () => {
  const {data: configs = []} = useGetConfigsQuery(undefined, { pollingInterval: fiveMinutes });
  const permissions = configs.find((config) => config.name === "permissions")?.settings;
  return Object.keys(permissions);
}

export function userIsSuper(){
  return store.getState().auth.loggedInUser.userRole ==="SUPER USER"
}

const usePermissions = (module: FeatureType) => {
  const dispatch = useDispatch();
  const {data: configs = []} = useGetConfigsQuery()
  const permissionsConfig = configs.find((config) => config.name === "permissions")?.settings;
  const {user: loggedInUser} = useAuthContext();

  if (!permissionsConfig) return () => false;
  if (loggedInUser) {
    return (action: ActionType, uiActionType:UIActionType = UIActionType.DISPLAY) => {
      const userHasPermission =
        permissionsConfig[loggedInUser.userRole][module][action];
        if(!userHasPermission && uiActionType === UIActionType.ONCLICK){
          dispatch(
            setGlobalAlert({
              show: true,
              severity: "warning",
              message: "You do not have permission to perform this action",
            })
          )


        }
      return userHasPermission;
    };
  }

  return () => false;
};

export default usePermissions;
