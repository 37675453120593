import * as React from "react";
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";
import {
  Tooltip,
  List,
  ListItem,
  IconButton as MuiIconButton,
  Popover as MuiPopover,
  ListItemText,
  styled
} from "@mui/material";
import { useAuthContext } from "auth/use-auth-context";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 100px;
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const {logout} = useAuthContext()

  const openMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeList = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "account-menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={openMenu}
          color="inherit"
        >
          <PowerSettingsNew />
        </IconButton>
      </Tooltip>
      <Popover
        id="account-menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeList}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <List disablePadding>
          <ListItem button onClick={logout}>
            <ListItemText primary="Sign out" />
          </ListItem>
        </List>
      </Popover>
    </React.Fragment>
  );
}

export default UserDropdown;
