import { useGetAreasQuery } from "store/services/areas";
import { useGetConfigsQuery } from "store/services/config";
import { useGetLookupByNameQuery } from "store/services/lookups";
import { useGetSitesQuery } from "store/services/sites";
import { LOOKUPS } from "store/slices/lookupSlice";

interface Props {}

const useRequiredDataLoadingGuad = () => {
  const { isLoading: configsLoading } = useGetConfigsQuery();
  const { isLoading: isLoadingSites } = useGetSitesQuery();
  const { isLoading: isLoadingAreas } = useGetAreasQuery();
  const { isLoading: productionLinesLoading } = useGetLookupByNameQuery(
    LOOKUPS.PRODUCTION_LINES
  );

  const dataLoading =
    configsLoading ||
    isLoadingSites ||
    isLoadingAreas ||
    productionLinesLoading;

  return dataLoading;
};

export default useRequiredDataLoadingGuad;
