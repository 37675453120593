import React from "react";
import  ThemeProvider from "theme"
import Routes from "routes/Routes";
import ErrorMessages from "components/errors/ErrorMessages";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import 'react-image-lightbox/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-phone-input-2/lib/material.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthProvider } from "auth/context/azure";
import { Provider } from "react-redux";
import store from "store/store";
import { msalInstance } from "auth/context/azure/msalInstance";
import { useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "utils/NavigationClient";


function App({ instance }: { instance: PublicClientApplication }) {

  const navigate = useNavigate();

  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);

  return (
    <Provider store={store}>
      <AuthProvider instance={instance}>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Routes />
            <ErrorMessages />
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
