import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { useNavigate } from 'react-router';

import { useAppDispatch } from 'store/store';
import { AuthContext } from 'auth/context/azure/auth-context';
import { loginRequest } from 'auth/authConfig';
import { IUser } from 'store/models/user.model';
import { useLoginMutation } from 'store/services/user';
import { setIsAuthorized, setLoginFailMesage, setLoggedInUser } from 'store/slices/authSlice';
import { HttpResponseWithFirebaseToken } from 'types/HttpResponse';
import Loader from 'components/Loader';
import useRequiredDataLoadingGuad from './useLoadingGuard';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

function AuthProviderWrapper({ children }: Props) {
  const { inProgress, instance } = useMsal();
  const dispatch = useAppDispatch();
  const [loginUser, { isLoading, isUninitialized }] = useLoginMutation();

  const navigate = useNavigate();
  const [loggedInUser, changeLoggedInUser] = useState<IUser | null>(null);
  const dataLoading = useRequiredDataLoadingGuad()

  let activeAccount: AccountInfo | null = null;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const activeAccountUserName = activeAccount?.idTokenClaims?.preferred_username;

  const handleNoAccount = useCallback(() => {
    changeLoggedInUser(null);
    dispatch(
      setLoginFailMesage({
        title: 'No HardCard account found.',
        subtitle: ' Please contact your administrator to have an account set up.',
      })
    );
    navigate('/404');
  }, [dispatch, navigate]);

  const handleMSALAuthFailure = useCallback(
    (error: any) => {
      changeLoggedInUser(null);
      dispatch(
        setLoginFailMesage({
          title: 'Authetication Failed',
          subtitle: 'You do not have permission to access this app',
        })
      );
      navigate('/403');
    },
    [dispatch, navigate]
  );

  const handleLoginRedirect = useCallback(async () => {
    instance
      .loginRedirect(loginRequest)
      .catch(handleMSALAuthFailure)
  }, [instance, handleMSALAuthFailure]);

  const handleLogoutPopup = useCallback(async () => {
    instance.logoutPopup({
      mainWindowRedirectUri: '/', // redirects the top level app after logout
      account: instance.getActiveAccount(),
    });
    changeLoggedInUser(null);
    dispatch(setIsAuthorized(false));
  }, [instance]);

  const handleServerNotResponding = useCallback(
    (error: any) => {
      changeLoggedInUser(null);
      dispatch(
        setLoginFailMesage({
          title: error.error,
          subtitle: 'The server is not responding. Please contact your administrator.',
        })
      );
      dispatch(setIsAuthorized(false));
      navigate('/500');
    },
    [dispatch, navigate]
  );

  const handleServerError = useCallback(
    (error: any) => {
      changeLoggedInUser(null);
      dispatch(
        setLoginFailMesage({
          title:
            error.message ||
            error?.data?.message ||
            "An unknown error has occurred",
          subtitle: 'Please contact your administrator.',
        })
      );
      navigate('/403');
      dispatch(setIsAuthorized(false));
    },
    
    [dispatch, navigate]
  );

  const handleDefaultError = useCallback(
    (error: any) => {
      changeLoggedInUser(null);
      dispatch(
        setLoginFailMesage({
          title:
            error.message ||
            error?.data?.message ||
            "An unknown error has occurred",
          subtitle: 'Please contact your administrator.',
        })
      );
      dispatch(setIsAuthorized(false));
      navigate('/403');
    },
    [dispatch, navigate]
  );

  const handleLoginError = useCallback(
    (error: any) => {
      switch (true) {
        case error.status === 'FETCH_ERROR':
          handleServerNotResponding(error);
          break;
        case error.status === 403:
          handleServerError(error);
          break;
        case error.status === 404:
          handleNoAccount();
          break;
        // case error.status === 409:
        //   handleVersionMismatch(error);
        //   break;
        default:
          handleDefaultError(error);
          break;
      }
    },
    [
      handleNoAccount,
      handleDefaultError,
      handleServerNotResponding,
      handleServerError,
      // handleVersionMismatch,
    ]
  );

useEffect(() => {
  if (activeAccountUserName && !loggedInUser) {
    loginUser()
      .unwrap()
      .then(({body}: HttpResponseWithFirebaseToken) => {
        if (body.email.toLowerCase() !== activeAccountUserName?.toLowerCase()) {
          throw new Error("User not found");
        }
        changeLoggedInUser(body);
        dispatch(setLoggedInUser(body));
        dispatch(setIsAuthorized(true));
      })
      .catch(handleLoginError);
  }
}, [
  activeAccountUserName,
  handleLogoutPopup,
  loginUser,
  handleNoAccount,
  loggedInUser,
  dispatch,
  navigate,
  handleLoginError,
]);

const apiAuthLoading = isLoading || (Boolean(activeAccountUserName) && isUninitialized && !loggedInUser) || (Boolean(loggedInUser) && dataLoading)


  const memoizedValue = useMemo(
    () => ({
      user: loggedInUser,
      setUser: changeLoggedInUser,
      method: 'azure',
      msalProgressStatus: inProgress,
      apiAuthLoading,
      error: !Boolean(activeAccount) && inProgress === 'none',
      authenticated: Boolean(activeAccount),
      unauthenticated: Boolean(activeAccount),
      //
      loginWithRedirect: handleLoginRedirect,
      logout: handleLogoutPopup,
    }),
    [
      handleLoginRedirect,
      handleLogoutPopup,
      activeAccount,
      inProgress,
      loggedInUser,
      isLoading,
      isUninitialized,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {apiAuthLoading ? (
        <Loader />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

// ----------------------------------------------------------------------

interface AuthProviderProps {
  instance: PublicClientApplication;
  children: React.ReactNode;
}

export const AuthProvider = ({ children, instance: msalInstance }: AuthProviderProps) => (
  <MsalProvider instance={msalInstance}>
    <AuthProviderWrapper>{children}</AuthProviderWrapper>
  </MsalProvider>
);
