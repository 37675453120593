import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStateType } from "store/store";
import {
  initialState as initialUserState,
  IUser,
} from "store/models/user.model";
import { LoadingStatus } from "types/LoadingStatus";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { app } from "utils/fileStorage/firebase";
import { HttpResponseWithFirebaseToken } from "types/HttpResponse";
import { userApi } from "store/services/user";


export interface AuthState {
  isAuthorized: boolean;
  authLoadingStatus: LoadingStatus;
  loginFailMessage?: {title: string, subtitle: string};
  loggedInUser: IUser;
}

const initialState: AuthState = {
  isAuthorized: false,
  loginFailMessage: {title: "", subtitle: ""},
  authLoadingStatus: LoadingStatus.LOADING,
  loggedInUser: initialUserState,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthorized(state, action: PayloadAction<boolean>) {
      state.isAuthorized = action.payload;
    },
    setLoggedInUser(state, action: PayloadAction<IUser>) {
      state.loggedInUser = action.payload;
    },
    logout(state, action: PayloadAction<void>) {
      state.loggedInUser = initialUserState;
      state.isAuthorized = false;
    },
    setAuthLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.authLoadingStatus = action.payload;
    },
    setLoginFailMesage(state, action: PayloadAction<{title: string, subtitle: string}>) {
      state.loginFailMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.login.matchFulfilled, (state, {payload}) => {
      authenticateFirebase(payload)     
    });
  }
});

export const {
  setIsAuthorized,
  setLoggedInUser,
  logout,
  setAuthLoadingStatus,
  setLoginFailMesage
} = authSlice.actions;

export const selectIsAuthorized = (state: AppStateType) =>
  state.auth.isAuthorized;

  export const selectLoginFailMessage = (state: AppStateType) =>
  state.auth.loginFailMessage;

export default authSlice.reducer;

export async function authenticateFirebase(response: HttpResponseWithFirebaseToken) {
  const auth = getAuth(app)
  return signInWithCustomToken(auth, response.firebaseToken)
    .catch((error: any) => {
      const newError = JSON.parse(error.message);
      // eslint-disable-next-line no-console
      console.log('Error authenticating with firebase', newError);
    });
}

export function selectLoggedInUser(state: AppStateType) {
  return state.auth.loggedInUser;
}
